import {useQueryClient} from 'react-query'
import {QUERIES, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ICollection} from '../../../_models'
import CollectionHeaderLangSwitcher from './CollectionHeaderLangSwitcher'
import {CollectionHeaderName} from './CollectionHeaderName'
import {CollectionHeaderNav} from './CollectionHeaderNav'
import {IWebsite} from "../../../../websites/core/_models";

interface Props {
  collection: ICollection
}

const CollectionHeader = ({collection}: Props) => {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const website = websitesData?.find((item) => item.id === collection?.website?.id)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed fit-content position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm002.svg')}
                  alt='Template'
                  style={{objectFit: 'cover'}}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <CollectionHeaderName collection={collection} />
            </div>
          </div>
          <CollectionHeaderLangSwitcher collection={collection} website={website} />
        </div>

        <CollectionHeaderNav />
      </div>
    </div>
  )
}

export {CollectionHeader}
