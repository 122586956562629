import {useFormikContext} from 'formik'
import ReactQuill from "react-quill";
import {InputTemplate} from "./InputTemplate";

interface Props {
  inputName: string
  value: any
  extended?: boolean
}

const quillOptions = {
  modules: {
    toolbar: [
      [{header: [1, 2, 3, 'normal']}],
      ['bold', 'italic', 'underline', 'link'],
      [{list: 'ordered'}, {list: 'bullet'},],
      [{ align: [] },],
      ['clean'],
    ],
  },
  formats: ['bold', 'italic', 'underline', 'link', 'list', 'align', 'header'],
}
const extendedQuillOptions = {
  modules: {
    toolbar: [
      [{header: [1, 2, 3, 'normal']}],
      ['bold', 'italic', 'underline', 'strike'],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['link', 'code-block'],
      [{ align: [] },],
      ['clean'],
    ],
  },
  formats: ['bold', 'italic', 'underline', 'strike', 'link', 'code-block', 'list', 'indent', 'align', 'header'],
}
export function QuillInput({inputName, value, extended = false}: Props) {
  const {setFieldValue} = useFormikContext()
  const options = extended?extendedQuillOptions:quillOptions;

  return (
    <>
      <ReactQuill
        {...options}
        theme='snow'
        value={value}
        onChange={(e) => setFieldValue(inputName, e)}
      />
      <InputTemplate
        inputName={inputName}
        type='textarea'
        fieldWidth='d-none'
      />
    </>
  )
}
