import {Formik, Form, FieldArray} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {CARDFOOTER_NOTIFICATIONS, KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {IEditArticle} from '../../../core/_models'
import {editArticle} from '../../../core/_requests'
import {useQueryClient} from 'react-query'
import {IWebsite} from '../../../../websites/core/_models'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import ReactQuill from 'react-quill'
import {QuillInput} from "../../../../../modules/custom/form-elements/QuillInput";

interface Props {
  article: IEditArticle
  invalidateArticle: () => void
}

export function ArticleText({article, invalidateArticle}: Props) {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === article.website.id)

  const {locale} = useParams()
  const currLocale = locale || website?.mainLocale || 'uk'

  const arrayDescr =
    article?.translations &&
    article?.translations[currLocale] &&
    article?.translations[currLocale].description
      ?.split(/<\/?p>/)
      .map((item) => item.trim())
      .filter(function (el) {
        return el !== ''
      })

  const initialValues: {description: string[]} = {
    description: arrayDescr && arrayDescr?.length > 0 ? arrayDescr : [''],
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {description: string[]}) => {
    setLoading(true)

    try {
      let translations = {
        ...article.translations,
        [currLocale]: {
          description: values.description.reduce((acc, curr) => acc + `<p>${curr.trim()}</p>`, ''),
        },
      }
      const dataToSend = {
        translations,
      }

      await editArticle(dataToSend, article.id)

      invalidateArticle()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <div className='card-header pt-5'>
        <h3>Текст статті</h3>
      </div>
      {article?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-text-blog'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                {article?.translations && (
                  <div>
                    <FieldArray
                      name='description'
                      render={(arrayHelpers) => (
                        <div className='d-flex flex-column flex-col-gutter'>
                          {values.description.map((p, index) => (
                            <div
                              key={index}
                              className={`row ${
                                index === values.description.length - 1
                                  ? 'd-flex align-items-center'
                                  : ''
                              }`}
                            >
                              <div
                                className={`col-lg-3 col-form-label fw-bold fs-6 d-flex flex-column flex-col-gutter align-items-center`}
                              >
                                {values.description.length > 1 && (
                                  <div>
                                    <button
                                      className='btn btn-sm btn-secondary d-flex align-items-center color-primary m-0'
                                      type='button'
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen040.svg'
                                        className='svg-icon-1x svg-icon-primary d-block m-0'
                                      />
                                    </button>
                                  </div>
                                )}
                                {index === values.description.length - 1 && (
                                  <div>
                                    <button
                                      className='btn btn-sm btn-secondary d-flex align-items-center color-primary'
                                      type='button'
                                      onClick={() => {
                                        arrayHelpers.push('')
                                      }}
                                    >
                                      {/* <FormattedMessage id='GENERAL.BUTTONS.ADD_GOODS' /> */}
                                      Додати абзац
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen041.svg'
                                        className='svg-icon-1x svg-icon-primary d-block m-0 mx-2'
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-9'>
                                <QuillInput inputName={`description.${index}`} value={values.description[index]} extended={true} />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
