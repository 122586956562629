import {ID, Response} from '../../../../../_metronic/helpers'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ICollection} from "../../../collections/_models";

export type ICategoryTranslation = Record<string, Record<string, string>>

export interface ICategoryForm {
  name: string
  active: number
  logo: string
  synonyms: string[]
  addPayment?: number | string
  translations: ICategoryTranslation
  customsCode: string
}

export interface ICategory {
  customsCode: string
  deliveryFee?: number | null
  directions?: {id: number}[]
  enabled: boolean
  id: number | string
  logo?: string | null
  logoUrl?: string | null
  image?: string | null
  imageUrl?: string | null
  name: string
  synonyms: string[]
  translations: ICategoryTranslation
  allowDelete?: boolean
}

export interface ICategorySelectOption extends IBasicSelect, ICategory {}
export interface ICollectionSelectOption extends IBasicSelect, ICollection {}

export type CategoriesQueryResponse = Response<Array<ICategory>>

export const categoriesLocales = ['uk', 'ru', 'en']
